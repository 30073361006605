import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import asyncComponent from '../../hoc/asyncComponent/asyncComponent';
import withAWSAuth from '../../hoc/withAWSAuth/withAWSAuth';
import Layout from '../../components/UI/Layout/Layout';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCogs,
  faTachometerAlt,
  faCameraRetro,
  faKeyboard,
  faNotEqual,
  faAddressBook,
  faPlus,
  faExclamation,
  faUserCircle,
  faChartBar
} from '@fortawesome/free-solid-svg-icons';
library.add(
  faCogs,
  faTachometerAlt,
  faCameraRetro,
  faKeyboard,
  faNotEqual,
  faAddressBook,
  faPlus,
  faExclamation,
  faUserCircle,
  faChartBar
);

const asyncClientDataPlatform = asyncComponent(() => {
  return import('../ClientDataPlatform/ClientDataPlatform');
})

const asyncAddClient = asyncComponent(() => {
  return import('../AddClient/AddClient');
})

const asyncEditClient = asyncComponent(() => {
  return import('../EditClient/EditClient');
})

const asyncPageNotFound = asyncComponent(() => {
  return import ('../ErrorPages/PageNotFound/PageNotFound');
})

const asyncForbidden = asyncComponent(() => {
  return import ('../ErrorPages/Forbidden/Forbidden');
})

const asyncUnauthorizedUser = asyncComponent(() => {
  return import ('../ErrorPages/UnauthorizedErrorPage/UnauthorizedErrorPage');
})

class App extends Component {
  render() {
    return (
      <div>
        <Layout>
          <Switch>
            <Route path='/add-client/' exact component={asyncAddClient} />
            <Route path='/edit-client/:client_id' exact component={asyncEditClient} />
            <Route path='/edit-client/' exact component={asyncEditClient} />
            <Route path='/' exact component={asyncClientDataPlatform} />
            <Route path="/unauthorized" component={asyncUnauthorizedUser} />
            <Route path="/forbidden" component={asyncForbidden} />
            <Route path="*" component={asyncPageNotFound} />
          </Switch>
        </Layout>
      </div>
    );
  }
}

export default withAWSAuth(withRouter(connect()(App)));
